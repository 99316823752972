import { useEffect, useState } from "react"
import axios from "axios";
import { useAuth } from "../contexts/auth";

const useClientsSimulations = (limit, page, keyWord, from, to, simulationStatus) => {
    const [datas, setDatas] = useState([]);
    const [refetch, setRefecth] = useState(false);
    const [similationsLoading, setSL] = useState(true);
    const { user } = useAuth();
    const { grade } = user;

    useEffect(async () => {
        try {
            let allDatas = null;
            let searchQuery = '';
            if (keyWord) {
                searchQuery += '&keyWord=' + keyWord
            }
            if (from) {
                searchQuery += '&from=' + from
            }
            if (to) {
                searchQuery += '&to=' + to
            }
            if (simulationStatus) {
                searchQuery += '&simulationStatus=' + simulationStatus
            }
            if (grade == 'Commercial') {
                searchQuery += '&commercialId=' + user._id
            }
            allDatas = await axios.get('/admin/simulations?limit=' + limit + '&currentPage=' + page + searchQuery)
            setDatas(allDatas.data)
        } catch (error) {

        } finally {
            setSL(false)
        }
        return setRefecth(false)
    }, [refetch])


    const refetchDatas = () => {
        setSL(true)
        setRefecth(true)
    };

    return { clientSimulations: datas, similationsLoading, refetchDatas };
}

export default useClientsSimulations;
import React from 'react'
import { PageTitle } from '../GlobalComponents/other';
import SimulationListFilterAndSearch from '../RoutesSubComponents/simulations/list';
import { useAuth } from '../contexts/auth';
import { withAuth } from '../hocs/withAuth';



function Simulations() {
    const { user } = useAuth();
    const authedInfos = user;

    return (
        <div className='simulations'>
            <PageTitle title={'Simulations des clients'} />
            <SimulationListFilterAndSearch
                authedInfos={authedInfos} />
        </div >
    )
}




export default withAuth(Simulations);